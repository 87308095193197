import { useTranslation } from 'react-i18next';

import { Head } from 'components/common';
import {
  ADQuality,
  BidFloor,
  CampaignType,
  Intro,
  Mediation,
  NaverKakao,
  SSP,
} from 'components/publishers/monetize';
import RewardAdPlus from 'components/publishers/monetize/reward-ad-plus';
import MANUALS from 'constants/manuals';
import Layout from 'layout/index';

const MonetizePage = () => {
  const { t } = useTranslation();

  return (
    <Layout starterConfig={{ mediaKit: MANUALS.monetize, contactHash: '#publishers' }}>
      <Head title="Monetize" description={t('publishers.monetize.intro.description')} />
      <Intro mediaKit={MANUALS.monetize} />
      <RewardAdPlus />
      <NaverKakao />
      <BidFloor />
      <SSP />
      <Mediation />
      <CampaignType />
      <ADQuality />
    </Layout>
  );
};

export default MonetizePage;
