import cn from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const RewardAdPlus = () => {
  const { t } = useTranslation();

  const { image } = useStaticQuery(
    graphql`
      query MonetizeSection1Query {
        image: file(absolutePath: { regex: "/publishers/monetize/reward-ad-plus.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  return (
    <section className="flex justify-center items-center bg-[#FBFCFD]">
      <div
        className={cn(
          'flex flex-col justify-center items-center',
          'lg:max-w-[1200px] lg:py-[100px] lg:px-0',
          'py-[50px] px-[20px]',
        )}
      >
        <h6
          className={cn(
            'text-center text-[#2685F4] text-[16px] leading-normal font-semibold',
            'lg:text-[14px] leading-[21px] lg:mb-[4px]',
            ' text-[16px] leading-[24px] mb-[2px]',
          )}
        >
          Naver Pay & ADPOPCORN
        </h6>
        <h3
          className={cn(
            'text-center text-[#212529] font-bold leading-[134%] tracking-[-0.3px] mb-[10px]',
            'lg:text-[36px] lg:whitespace-nowrap',
            'text-[24px] whitespace-pre-wrap',
          )}
        >
          {t('publishers.monetize.rewardAdPlus.title')}
        </h3>
        <p
          className={cn(
            'text-[#808C99] font-normal tracking-[-0.3px] leading-[160%] text-center',
            'lg:text-[18px]',
            'text-[16px] whitespace-pre-wrap',
          )}
        >
          {t('publishers.monetize.rewardAdPlus.description')}
        </p>
        <div
          className={cn(
            'flex flex-wrap justify-center',
            'lg:gap-[12px] lg:mt-[30px] lg:mb-[50px]',
            'w-full gap-[10px] px-[20px] mt-[20px] mb-[30px]',
          )}
        >
          <Link
            className={cn(
              'flex items-center justify-center text-[#6B7280] tracking-[-0.3px] bg-white border border-[#D1D5DB] rounded-[6px] py-[12px] px-[20px] hover:bg-[#F7F8FA] focus:outline-none',
              'lg:flex-initial lg:py-[12px]',
              'flex-1 py-[14px]',
            )}
            to="/reward-ad-plus"
          >
            {t('details')}
          </Link>
          <Link
            className={cn(
              'flex items-center justify-center text-white tracking-[-0.3px] bg-[#2685F4] px-[20px] rounded-[6px] hover:bg-[#2685F4]/80 focus:outline-none',
              'lg:flex-initial lg:py-[12px]',
              'flex-1 py-[14px]',
            )}
            to="/contact#publishers"
          >
            {t('contact')}
          </Link>
        </div>
        <GatsbyImage
          objectFit="contain"
          className={cn('lg:w-[651px] lg:h-[188px]', 'w-[320px] h-[298px]')}
          image={image.childImageSharp.gatsbyImageData}
          alt="reward-ad-plus.png"
        />
      </div>
    </section>
  );
};

export default RewardAdPlus;
